import { ref, computed } from "vue";
import { Store, defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { displayAlert } from "../main";

import {
    IO,
    authorizeInSocketIo,
    initIoEvents,
    ioConnect,
    ioDisconnect
} from "../plugins/socketIo";
import { httpJsonRequest } from "../plugins/fetchApi";

import { User, UserRole, ApiUserMeResponse } from "../types/user";
import { AuthData, ApiAuthRefreshTokenResponse, ApiAuthRevokeTokenResponse } from "../types/auth";
import { UserDevice } from "../types/user-device";
import { useChatsStore } from "./chats";
import { useFetchedElementsStore } from "./fetched-elements";
import { ApiChatResponse, ChatThusappr, LAST_DISPLAYED_CHAT_PREFERENCES_KEY } from "@/types/chat";
import { Capacitor } from "@capacitor/core";
import { StorageSerializers, useLocalStorage } from "@vueuse/core";

export type LogUserOutOpts = {
    alert_type?: "error" | "info";
    alert_msg?: string;
    logout_from_all_devices?: boolean;
    redirect_route_name?: string;
};

export const useAuthStore = defineStore("auth", () => {
    /*###############
    ### CONSTANTS ###
    ############## */
    const AD_PREFERENCES_KEY = "AuthData";

    /*###############
    ### VARIABLES ###
    ############## */
    const user = Capacitor.isNativePlatform()
        ? ref<User>()
        : useLocalStorage<User>("Stores.Auth.User", null, {
              serializer: StorageSerializers.object
          });
    const auth_data = Capacitor.isNativePlatform()
        ? ref<AuthData>()
        : useLocalStorage<AuthData>("Stores.Auth.AuthData", null, {
              serializer: StorageSerializers.object
          });
    const user_permissions = Capacitor.isNativePlatform()
        ? ref<string[]>([])
        : useLocalStorage<string[]>("Stores.Auth.UserPermissions", [], {
              serializer: StorageSerializers.object
          });
    const user_device = Capacitor.isNativePlatform()
        ? ref<UserDevice>()
        : useLocalStorage<UserDevice>("Stores.Auth.UserDevice", null, {
              serializer: StorageSerializers.object
          });
    const user_data_invalidated = ref<boolean>(false);

    let refreshing_auth_data_promise: null | Promise<void> = null;
    let logout_promise: null | Promise<void> = null;

    /*##############
    ### COMPUTED ###
    ############# */
    const is_logged_id = computed(() => auth_data.value !== undefined && auth_data.value !== null);

    /*#############
    ### GETTERS ###
    ############ */
    function userHasPermissions(permissions: string | string[]) {
        if (typeof permissions == "string") permissions = [permissions];
        let has_all = true;
        for (let i = 0; i < permissions.length; i++) {
            const ix = user_permissions.value.indexOf(permissions[i]);
            if (ix === -1) {
                has_all = false;
                break;
            }
        }
        return has_all;
    }

    function getUserInitials(subject_user?: User) {
        if (!subject_user) subject_user = user.value;
        if (!subject_user) return "?";

        let i = "";

        if (subject_user.first_name) {
            i += subject_user.first_name.slice(0, 1);
        }
        if (subject_user.last_name) {
            i += subject_user.last_name.slice(0, 1);
        }

        return i;
    }

    function getUserTimezone(subject_user?: User) {
        if (!subject_user) subject_user = user.value;
        if (!subject_user) return "Europe/Warsaw";

        return subject_user.settings.timezone_select_automatically
            ? subject_user.settings.timezone_auto_assigned
            : subject_user.settings.timezone_manually_selected;
    }

    function getUserFullName(subject_user?: User) {
        if (!subject_user) subject_user = user.value;
        if (!subject_user) return "n/a";

        return `${subject_user.first_name} ${subject_user.last_name}`.trim();
    }

    /*#############
    ### SETTERS ###
    ############ */
    function setUser(u: User) {
        user.value = u;
    }
    function unsetUser() {
        user.value = undefined;
    }

    async function setAuthData(ad: AuthData) {
        if (
            !ad ||
            !ad.access_token ||
            !ad.access_token_exp_date ||
            !ad.refresh_token ||
            !ad.refresh_token_exp_date
        ) {
            console.error(ad);
            throw new Error("Malformed AuthData");
        }

        auth_data.value = {
            access_token: ad.access_token,
            access_token_exp_date: ad.access_token_exp_date,
            refresh_token: ad.refresh_token,
            refresh_token_exp_date: ad.refresh_token_exp_date
        };

        if (Capacitor.isNativePlatform()) {
            await Preferences.set({
                key: AD_PREFERENCES_KEY,
                value: JSON.stringify(auth_data.value)
            });
        }
    }
    async function unsetAuthData() {
        auth_data.value = undefined;

        if (Capacitor.isNativePlatform()) {
            await Preferences.remove({ key: AD_PREFERENCES_KEY });
        }
    }

    function setUserPermissions(permissions: string | string[]) {
        if (!Array.isArray(permissions)) permissions = [permissions];
        user_permissions.value = permissions;
    }
    function unsetUserPermissions() {
        user_permissions.value = [];
    }

    function setUserDevice(device: UserDevice) {
        user_device.value = device;
    }
    function unsetUserDevice() {
        user_device.value = undefined;
    }

    /*#############
    ### METHODS ###
    ############ */
    function isAuthDataValid(obj: Record<string, string | number>) {
        if (
            obj.access_token !== undefined &&
            typeof obj.access_token === "string" &&
            obj.access_token != "" &&
            obj.refresh_token !== undefined &&
            typeof obj.refresh_token === "string" &&
            obj.refresh_token != "" &&
            obj.access_token_exp_date !== undefined &&
            typeof obj.access_token_exp_date === "number" &&
            obj.access_token_exp_date > 0 &&
            obj.refresh_token_exp_date !== undefined &&
            typeof obj.refresh_token_exp_date === "number" &&
            obj.refresh_token_exp_date > 0
        ) {
            return true;
        }
        return false;
    }

    function fetchUserData(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (auth_data.value === undefined) {
                return reject("You need to set AuthData before calling fetchUserData() method");
            }

            try {
                const r = await httpJsonRequest<ApiUserMeResponse>(`/users/me`, {
                    method: "GET"
                });
                if (r.__meta.from_cache) {
                    user_data_invalidated.value = true;
                } else {
                    user_data_invalidated.value = false;
                }

                if (r.user.role === UserRole.ADMIN) {
                    return reject("Invalid user role");
                }

                setUser(r.user);
                setUserPermissions(r.user_permissions);
                setUserDevice(r.user_device);

                if (r.user.role === UserRole.USER) {
                    gtag("set", "user_properties", {
                        user_segment: r.user.user_segment._id
                    });

                    const UD_O: Record<string, any> = {
                        email: r.user.email
                    };
                    if (r.user.phone && r.user.phone_cc) {
                        UD_O.phone_number = `+${r.user.phone_cc}${r.user.phone.replace(
                            /[^0-9]/g,
                            ""
                        )}`;
                    }

                    gtag("set", "user_data", UD_O);

                    window.dataLayer.push({
                        email: UD_O.email
                    });
                    if (UD_O.phone_number) {
                        window.dataLayer.push({
                            phone: UD_O.phone_number
                        });
                    }
                }

                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    }

    function logUserIn(this: Store, ad: AuthData): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await setAuthData(ad);

                try {
                    await fetchUserData();
                } catch (err) {
                    await logUserOut.call(this, {
                        alert_msg:
                            "Nie udało się pobrać danych użytkownika - logowanie zostało anulowane.",
                        alert_type: "error",
                        redirect_route_name: "auth-signin"
                    });
                }

                const chats_store = useChatsStore();
                // 2. Pobieramy chats, aby móc wyświetlić liczbę nieodczytanych chatów (zakładając, że nie będzie więcej niż 64 nieodczytane konwersacje)
                chats_store
                    .fetchChats()
                    .then(async () => {
                        // 3. Sprawdzamy, czy chat jest dostępny w localStorage
                        const LCI = await Preferences.get({
                            key: LAST_DISPLAYED_CHAT_PREFERENCES_KEY
                        });

                        // 3.1 Jeśli jest
                        if (LCI.value) {
                            // 3.2 Sprawdzamy, czy chat jest w store
                            const chat_stored = chats_store.getChat(LCI.value);

                            // 3.3 Jeśli nie ma, to próbujemy go pobrać
                            if (!chat_stored) {
                                httpJsonRequest<ApiChatResponse<ChatThusappr>>(
                                    `/chats/${LCI.value}`,
                                    {},
                                    { supress_errors: true }
                                )
                                    .then(res => {
                                        chats_store.insertChat(res.chat);
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            }
                            // 3.4 Ustawiamy ostatni chat w store
                            chats_store.last_chat_id = LCI.value;
                        }
                    })
                    .catch(e => console.error(e));

                if (!IO.connected) {
                    initIoEvents();
                    try {
                        await ioConnect();
                        await authorizeInSocketIo();
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    initIoEvents();
                    await authorizeInSocketIo();
                }

                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    }

    function logUserOut(this: Store, opts?: LogUserOutOpts): Promise<void> {
        if (logout_promise !== null) {
            return logout_promise;
        }

        logout_promise = new Promise(async resolve => {
            if (!auth_data.value) return resolve();

            this.router.push({
                name: opts && opts.redirect_route_name ? opts.redirect_route_name : "welcome"
            });

            const RT = auth_data.value.refresh_token;
            unsetAuthData();

            try {
                await httpJsonRequest<ApiAuthRevokeTokenResponse>(`/auth/revoke-token`, {
                    method: "POST",
                    body: JSON.stringify({
                        refresh_token: RT,
                        logout_from_all_devices: opts && opts.logout_from_all_devices === true
                    })
                });
            } catch (err) {
                console.error(err);
            }

            unsetUserPermissions();
            unsetUserDevice();
            unsetUser();

            const chats_store = useChatsStore();
            chats_store.nuke();

            const fetched_elements_store = useFetchedElementsStore();
            fetched_elements_store.nuke();

            await Preferences.remove({ key: LAST_DISPLAYED_CHAT_PREFERENCES_KEY });

            if (IO.connected) {
                ioDisconnect();
            }

            if (opts && opts.alert_type === "info") {
                displayAlert.info(opts && opts.alert_msg ? opts.alert_msg : "Wylogowano pomyślnie");
            } else {
                displayAlert.error(
                    opts && opts.alert_msg ? opts.alert_msg : "Wylogowano pomyślnie"
                );
            }

            logout_promise = null;
            return resolve();
        });
        return logout_promise;
    }

    function refreshAuthData(ad: AuthData): Promise<void> {
        // console.log("REFRESH REQUEST");
        if (refreshing_auth_data_promise != null) {
            // console.log("REFRESH QUEUED");
            return refreshing_auth_data_promise;
        }

        refreshing_auth_data_promise = new Promise(async resolve => {
            // console.log("REFRESH EXEC");
            try {
                const RES = await httpJsonRequest<ApiAuthRefreshTokenResponse>(
                    `/auth/refresh-token`,
                    {
                        method: "POST",
                        body: JSON.stringify(ad)
                    }
                );

                // console.log(RES);
                if (RES.success) {
                    await setAuthData(RES.data);

                    if (IO.connected) {
                        try {
                            await authorizeInSocketIo();
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }

            setTimeout(() => {
                refreshing_auth_data_promise = null;
            }, 100);
            return resolve();
        });
        return refreshing_auth_data_promise;
    }

    return {
        AD_PREFERENCES_KEY,

        user,
        auth_data,
        user_permissions,
        user_device,
        user_data_invalidated,

        is_logged_id,

        userHasPermissions,
        getUserInitials,
        getUserTimezone,
        getUserFullName,

        setUser,
        unsetUser,
        setAuthData,
        unsetAuthData,
        setUserPermissions,
        unsetUserPermissions,
        setUserDevice,
        unsetUserDevice,
        fetchUserData,

        isAuthDataValid,
        logUserIn,
        logUserOut,
        refreshAuthData
    };
});
