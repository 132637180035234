import { LocationQueryValue } from "vue-router";
import { i18n } from "../plugins/i18n";
import moment from "moment";

export const REGEXES = {
    EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    OTP: /^\d{6}$/,
    OBJECT_ID: /^[0-9a-fA-F]{24}$/,
    SHA512: /^[a-f0-9]{128}$/i,
    YEAR: /^[0-9]{4}$/,
    POSITIVE_INT: /^[1-9]\d*$/,
    PESEL: /^[0-9]{11}$/,
    KRS: /^[0-9]{10}$/,
    NIP: /^[0-9]{10}$/,
    REGON: /^([0-9]{9}|[0-9]{14})$/
};

export function areSamePasswords(value: string, comparedValue: string): boolean | string {
    if (value !== comparedValue) {
        return "Hasła nie są takie same";
    }
    return true;
}

export function isSameAs(value: string, comparedValue: string): boolean | string {
    if (value !== comparedValue) {
        return "Wartości nie są takie same";
    }
    return true;
}

export function isDifferentThan(value: string, comparedValue: string): boolean | string {
    if (value === comparedValue) {
        return "Adres nie może być taki sam jak aktualny";
    }
    return true;
}

export function isEmail(value: string): boolean | string {
    if (value.length > 127) {
        return "Adres e-mail może mieć co najwyżej 127 znaków długości";
    }
    if (!REGEXES.EMAIL.test(value)) {
        return "Niepoprawny adres e-mail";
    }
    return true;
}

export function isValidOtp(value: string): boolean | string {
    const PV = value.replace(/ /g, "");
    if (!REGEXES.OTP.test(PV)) {
        return "Kod musi składać się z 6 cyfr";
    }
    return true;
}

export function isValidBlikCode(value: string): boolean | string {
    const PV = value.replace(/ /g, "");
    if (!/^[0-9]{6}$/.test(PV)) {
        return "Podaj poprawny kod BLIK";
    }
    return true;
}

export function isValidKRS(value: string): boolean {
    if (!value || typeof value !== "string" || !REGEXES.KRS.test(value)) {
        return false;
    }
    return true;
}

export function validateKRS(value: string): boolean | string {
    if (value.length !== 10) {
        return "KRS musi mieć 10 znaków";
    }
    if (!isValidKRS(value)) {
        return "Niepoprawny KRS";
    }
    return true;
}

function isValidZipCode(code: string): boolean {
    if (!code || typeof code !== "string" || code.length < 2 || code.length > 15) {
        return false;
    }

    if (!/^[A-Za-z0-9\-\/]+$/.test(code)) {
        return false;
    }

    return true;
}

export function validateZipCode(value: string, country: string): boolean | string {
    if (value.length < 2 || value.length > 15) {
        return "Kod pocztowy musi mieć od 2 do 15 znaków";
    }

    if (country.toLowerCase() === "pl" && !/^[0-9]{2}-[0-9]{3}$/.test(value)) {
        return "Kod pocztowy w Polsce musi mieć format 00-000";
    }

    if (!isValidZipCode(value)) {
        return "Niepoprawny kod pocztowy";
    }
    return true;
}

function getBirthDateFromPESEL(pesel: string) {
    if (!pesel || typeof pesel !== "string") return undefined;
    if (!REGEXES.PESEL.test(pesel)) return undefined;

    const YEAR_DIGITS = pesel.slice(0, 2);
    const MONTH_DIGITS = pesel.slice(2, 4);
    const DATE_DIGITS = pesel.slice(4, 6);

    const MONTH_NUM = parseInt(MONTH_DIGITS) % 20;
    const MONTH_BASE = parseInt(MONTH_DIGITS) - MONTH_NUM;
    let year_start_digits = "19";
    if (MONTH_BASE === 20) {
        year_start_digits = "20";
    } else if (MONTH_BASE === 40) {
        year_start_digits = "21";
    } else if (MONTH_BASE === 60) {
        year_start_digits = "22";
    } else if (MONTH_BASE === 80) {
        year_start_digits = "18";
    }

    return {
        year: `${year_start_digits}${YEAR_DIGITS}`,
        month: MONTH_NUM.toString().padStart(2, "0"),
        date: DATE_DIGITS
    };
}

export function isValidPESEL(num: string): boolean {
    if (!num || typeof num !== "string") return false;
    if (!REGEXES.PESEL.test(num)) return false;

    // 1. Walidacja sumy kontrolnej
    const WEIGHTS = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    const DIGITS = num.split("").map(it => parseInt(it));
    DIGITS.pop();
    let checksum = 0;
    for (let i = 0; i < DIGITS.length; i++) {
        checksum += (DIGITS[i] * WEIGHTS[i]) % 10;
    }
    checksum = checksum % 10;
    const CS_DIGIT = parseInt(num.slice(10));

    if (
        (checksum === 0 && checksum !== CS_DIGIT) ||
        (checksum !== 0 && CS_DIGIT !== 10 - checksum)
    ) {
        return false;
    }

    // 2. Walidacja daty urodzenia
    const R = getBirthDateFromPESEL(num);
    if (R === undefined || !moment(`${R.year}-${R.month}-${R.date}`).isValid()) {
        return false;
    }

    return true;
}

export function validatePESEL(value: string): boolean | string {
    if (value.length !== 11) {
        return "PESEL musi mieć 11 znaków";
    }
    if (!isValidPESEL(value)) {
        return "Niepoprawny PESEL";
    }
    return true;
}

export function isValidNIP(nip: string): boolean {
    if (!nip || typeof nip !== "string" || !REGEXES.NIP.test(nip)) return false;

    const EXCLUDED_NIPS = ["0000000000"];
    if (EXCLUDED_NIPS.includes(nip)) return false;

    let csum = 0;
    const CSUM_WEIGHTS = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const CSUM_DIGITS = nip
        .slice(0, 9)
        .split("")
        .map(it => parseInt(it));
    for (let i = 0; i < CSUM_DIGITS.length; i++) {
        csum += CSUM_DIGITS[i] * CSUM_WEIGHTS[i];
    }
    csum = csum % 11;
    if (csum === 10 || csum !== parseInt(nip.slice(9, 10))) {
        return false;
    }

    return true;
}

export function validateNIP(value: string): boolean | string {
    if (value.length !== 10) {
        return "NIP musi mieć 10 znaków";
    }
    if (!isValidNIP(value)) {
        return "Niepoprawny NIP";
    }
    return true;
}

function isValid9DigitsREGON(regon: string): boolean {
    if (!regon || typeof regon !== "string" || !/^[0-9]{9}$/.test(regon)) {
        return false;
    }

    let csum = 0;
    const CSUM_WEIGHTS = [8, 9, 2, 3, 4, 5, 6, 7];
    const CSUM_DIGITS = regon
        .slice(0, 8)
        .split("")
        .map(it => parseInt(it));
    for (let i = 0; i < CSUM_DIGITS.length; i++) {
        csum += CSUM_DIGITS[i] * CSUM_WEIGHTS[i];
    }
    csum = csum % 11;
    if (csum === 10) csum = 0;
    if (csum !== parseInt(regon.slice(8, 9))) {
        return false;
    }

    return true;
}

function isValid14DigitsREGON(regon: string): boolean {
    if (!regon || typeof regon !== "string" || !/^[0-9]{14}$/.test(regon)) {
        return false;
    }

    if (!isValid9DigitsREGON(regon.slice(0, 9))) {
        return false;
    }

    let csum = 0;
    const CSUM_WEIGHTS = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    const CSUM_DIGITS = regon
        .slice(0, 13)
        .split("")
        .map(it => parseInt(it));
    for (let i = 0; i < CSUM_DIGITS.length; i++) {
        csum += CSUM_DIGITS[i] * CSUM_WEIGHTS[i];
    }
    csum = csum % 11;
    if (csum === 10) csum = 0;
    if (csum !== parseInt(regon.slice(13, 14))) {
        return false;
    }

    return true;
}

export function isValidREGON(regon: string): boolean {
    if (!regon || typeof regon !== "string" || !REGEXES.REGON.test(regon)) {
        return false;
    }

    if (regon.length === 9) {
        return isValid9DigitsREGON(regon);
    }
    return isValid14DigitsREGON(regon);
}

export function validateREGON(value: string): boolean | string {
    if (value.length !== 9 && value.length !== 14) {
        return "REGON musi mieć 9 lub 14 znaków";
    }
    if (!isValidREGON(value)) {
        return "Niepoprawny REGON";
    }
    return true;
}

export function isRequired<T>(value: T | T[]): boolean | string {
    if (typeof value === "string" && value.trim() === "") {
        return i18n.global.t("global.validation_errors.required");
    } else if (Array.isArray(value) && value.length === 0) {
        return i18n.global.t("global.validation_errors.required");
    }
    return true;
}

export function isSafePassword(value: string): boolean | string {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);

    if (!hasUpperCase) {
        return "Hasło musi zawierać conajmniej jedną wielką literę";
    }
    if (!hasLowerCase) {
        return "Hasło musi zawierać conajmniej jedną małą literę";
    }
    if (!hasNumber) {
        return "Hasło musi zawierać conajmniej jedną cyfrę";
    }

    return true;
}

export function minMax(min: number, max: number) {
    return (v: string) => {
        if (v.length < min) {
            return i18n.global.t("global.validation_errors.min_length", { n: min });
        }
        if (v.length > max) {
            return i18n.global.t("global.validation_errors.max_length", { n: max });
        }
        return true;
    };
}

export function numberMinMax(min: number, max: number) {
    return (v: number | string) => {
        if (typeof v == "string") {
            v = parseInt(v);
        }
        if (v < min) {
            return `Minimalna wartość to ${min}`;
        }
        if (v > max) {
            return `Maksymalna wartość to ${max}`;
        }
        return true;
    };
}

export function isDiscountCodeValid(v: string) {
    if (!/^[a-z0-9][a-z0-9_\-]{2,30}$/i.test(v)) {
        return "Proszę podać poprawny kod zniżkowy";
    }

    return true;
}

export function isValidCssColor(str: string) {
    const s = new Option().style;
    s.color = str;
    return s.color !== "";
}

/**
 * Metoda sprawdza, czy przekazana data jest dniem dzisiejszym.
 * @param {number|Date} date Data do sprawdzenia
 * @returns {boolean}
 */
export function isTodayDate(date: number | Date = Date.now()) {
    return new Date(date).toISOString().split("T")[0] == new Date().toISOString().split("T")[0];
}

export function isRouteQueryParamValidObjectId(id: LocationQueryValue | LocationQueryValue[]) {
    if (id === undefined) return false;
    return typeof id === "string" && REGEXES.OBJECT_ID.test(id);
}
